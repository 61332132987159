import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    // window.scrollTo(0, 0);
  }

}
  // constructor(public auth: AuthService) {
    // auth.handleAuthentication();


  // }

  // public login(): void {
    // this.auth.login();
  // }

  // salir() {
   // this.auth.logout();
  // }

// }
