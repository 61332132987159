//export const URL_SERVICIOS = "http://35.227.154.115/rest/index.php"; //RTAX
export const URL_SERVICIOS = "http://35.230.2.140/rest/index.php"; //ONTEX
//export const URL_IMAGENES="http://localhost/rest/public/img/productos/";
//export const URL_IMAGENES="http://35.227.154.115/bcab/img/"; //RTAX
export const URL_IMAGENES="http://35.230.2.140/bcab/img/"; //OTEX
//export const IMG_PROPS="http://localhost/bprop/imgs/props/";
export const IMG_PROPS="http://35.227.154.115/bprop/imgs/props/";



