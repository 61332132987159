import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Viaje } from "./../modelos/viajes";

//importar para usar servicios http
import {HttpModule} from '@angular/http';

import "rxjs/add/operator/map";
import { URL_SERVICIOS } from "../config/url.rtax";
 
@Injectable()
export class ViajesService {
  viajes: any[] = [];
  loadingIndicator: boolean = true;
   constructor(private http: Http) {
   }
 
   /*getViajes(id_driver): Observable<Viaje[]> {

    const params = { id_driver: id_driver};
      return this.http.post("http://bcab.cl/rest/index.php/viajes/viajes_driver/",params)
         .map((res: Response) => res.json())
         .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
   }
*/

   getViajes(id_driver){

    console.log(id_driver);
  
    const params = { id_driver: id_driver};
    

    let promesa = new Promise((resolve, reject) => {
      let url = URL_SERVICIOS + "/viajes/viajes_driver/";
      //llamo al servicio
      this.http
        .post(url,params)
        .map(resp => resp.json())
        .subscribe(data => {
          console.log("dataVIaje",data);
          this.viajes.push(...data.viajes_driver);

          this.loadingIndicator=false;
          resolve(this.viajes);
        },err=>{
          console.log("Error al generar id_viaje",err);
          reject(err);
        }
      
      );
    });
    

    return promesa;

  }
}