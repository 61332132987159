import { Injectable, } from '@angular/core';
import {Router,CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/auth.service";
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,private auth:AuthService) { }

 canActivate():boolean {
    console.log(this.auth.isAuthenticated())
    if(this.auth.isAuthenticated()){
      console.log("pasó el guard");
      return  true;
   }
   else{
     console.log("no pasó el guard");
     return false;

   }
  }
  
}
