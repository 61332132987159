import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { AyudaComponent} from './components/ayuda/ayuda.component';
import { ConductorComponent} from './components/conductor/conductor.component';
import { PasajeroComponent} from './components/pasajero/pasajero.component';
import { CorporativoComponent} from './components/corporativo/corporativo.component';
import { CaracteristicasComponent} from './components/caracteristicas/caracteristicas.component';

import {AuthService} from './services/auth.service';


export const ROUTES: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'ayuda', component: AyudaComponent },
    { path: 'conductor', component: ConductorComponent },
    { path: 'pasajero', component: PasajeroComponent },
    { path: 'corporativo', component: CorporativoComponent },
    { path: 'caracteristicas', component: CaracteristicasComponent },
    { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

