import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { ROUTES } from './app.routes';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule, AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';

import {MatGridListModule} from '@angular/material/grid-list';

import {HttpModule} from '@angular/http';

// services
import {ViajesService} from '../app/services/viajes.service';
import {DataTableModule} from 'angular2-datatable';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// auth0 service
import {AuthService} from './services/auth.service';

// authguardservice
import {AuthGuardService} from './services/auth-guard.service';
import { PasajeroComponent } from './components/pasajero/pasajero.component';
import { ConductorComponent } from './components/conductor/conductor.component';
import { AyudaComponent } from './components/ayuda/ayuda.component';
import { CaracteristicasComponent } from './components/caracteristicas/caracteristicas.component';
import { CorporativoComponent } from './components/corporativo/corporativo.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    NavbarComponent,
    HomeComponent,
    PasajeroComponent,
    ConductorComponent,
    AyudaComponent,
    CaracteristicasComponent,
    CorporativoComponent
  ],
  imports: [
    BrowserModule,
    NgxDatatableModule,
    DataTableModule,
    AngularFireDatabaseModule, AngularFireAuthModule,
    HttpModule,
    MatGridListModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAf0EpvXgSNHiJ1up8EuplFkSoAl4k-88k'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule.forRoot(ROUTES, {useHash: true})
  ],
 providers: [ViajesService,AuthService,AuthGuardService],
 // providers: [ViajesService],
  bootstrap: [AppComponent]
})
export class AppModule { }

