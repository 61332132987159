import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../../app.routes';
import { Router } from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public auth: AuthService) {
    auth.handleAuthentication();


  }


  ngOnInit() {
    window.scrollTo(0, 0);
  }

  public login(): void {
    this.auth.login();
   }

     salir() {
    this.auth.logout();
   }


}
